// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Budget_budget-page__2JubR {
	margin-left: 12rem;
	margin-top:  1rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Budget.module.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,iBAAiB;AAClB","sourcesContent":[".budget-page {\r\n\tmargin-left: 12rem;\r\n\tmargin-top:  1rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"budget-page": `Budget_budget-page__2JubR`
};
export default ___CSS_LOADER_EXPORT___;
