import './App.css';
import {Route, Routes, Navigate} from 'react-router-dom';
import Budget from "./pages/Budget";
import Layout from "./components/layout/Layout";
 
function App() {

  return (
    <Layout>
      <Routes>
        <Route path="/" exact element={<Budget />} />
        <Route path="/budget" element={<Budget />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Layout>
  );
}

export default App;
