import {useState} from 'react';
import styles from './Budget.module.css';

const Budget = () => {
    const[budgetPosts, setBudgetPosts] = useState('budgetPosts');

	return (
		<div className={styles['budget-page']}>
			<p> Hello world!</p>
		</div>
	)
}

export default Budget;